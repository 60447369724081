import { Fragment, useEffect, useState } from 'react';
// import styles
import classes from './TextProtocol.module.css';

// import fontawesome icons for editing symbol
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faXmark } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import SocketContext from '../../store/socket-context';
import useHttp from '../../hooks/use-http';

function TextProtocolEntry(props) {
  const [isCommenting, setIsCommenting] = useState(false);
  const [comment, setComment] = useState(props.data.comment);
  const authCtx = useContext(AuthContext);
  const { sendRequest } = useHttp();

  // define options for displaying the date
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  // FUNCTIONS FOR COMMENTING

  // send data to backend
  function onCommentSubmitHandler() {
    setIsCommenting(false);
    sendRequest({
      url: '/api/v1/protocol/' + props.id + '/comment',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authCtx.token,
      },
      body: {
        comment: comment,
      },
    });
  }

  // frontend funtions
  function onCommentHandler() {
    setIsCommenting(true);
  }

  function onCommentCancelHandler() {
    setIsCommenting(false);
  }

  function onCommentChangeHandler(event) {
    setComment(event.target.value);
  }

  // assign the correct style to a certain entry level class --> danger, warning, info, success
  function convertToClass(entryLevel) {
    switch (entryLevel) {
      case 'danger':
        entryLevel = classes.danger;
        break;

      case 'warning':
        entryLevel = classes.warning;
        break;

      case 'info':
        entryLevel = classes.info;
        break;

      case 'success':
        entryLevel = classes.success;
        break;

      default:
        entryLevel = classes.info;
        break;
    }
    return entryLevel;
  }
  // ONE SINGLE DATA ENTRY
  return (
    // Table row = one data entry
    <tr className={convertToClass(props.level)}>
      {/* date */}
      <td className={classes.tableDate}>
        {new Date(parseInt(props.date)).toLocaleString('de-DE', timeOptions)}
        <span> Uhr, </span>
        {new Date(parseInt(props.date)).toLocaleString('de-DE', dateOptions)}
      </td>
      {/* date message */}
      <td className={classes.tableInfo}>
        <b>{props.keyword}</b> {props.message}
        {isCommenting === false ? (
          <i>
            {comment}
            <FontAwesomeIcon
              onClick={onCommentHandler}
              className={classes.icon}
              icon={faPencil}
            />
          </i>
        ) : (
          <form
            onSubmit={onCommentSubmitHandler}
            className={classes.commentForm}
          >
            <textarea
              className={convertToClass(props.level)}
              value={comment}
              onChange={onCommentChangeHandler}
            />
            <input
              type="submit"
              value="Bestätigen"
              className={classes.commentFormButton}
            />
            <span className={classes.cancelIcon}>
              <FontAwesomeIcon
                onClick={onCommentCancelHandler}
                icon={faXmark}
              />
            </span>
          </form>
        )}
      </td>
    </tr>
  );
}

function TextProtocol(props) {
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [curPage, setCurPage] = useState(1);
  const authCtx = useContext(AuthContext);
  const socketCtx = useContext(SocketContext);
  const { isLoading, _, sendRequest: getEntries } = useHttp();
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (authCtx.token && socketCtx.socket) {
      const handler = (item) => {
        setItems((oldItems) => [...oldItems, item]);
        setOffset((o) => o + 1);
      };
      socketCtx.socket.on('protocol', handler);
      return () => socketCtx.socket.off('protocol', handler);
    }
  }, [authCtx.token, socketCtx.socket]);

  // shall have no dependencies despite warning
  useEffect(() => {
    getEntries(
      {
        url:
          '/api/v1/protocol?_page=' + curPage + '&_limit=40&_offset=' + offset,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        setItems([...data.docs.reverse()]);
        setFirstLoad(true);
      }
    );
  }, []);

  function fetchMoreData() {
    getEntries(
      {
        url:
          '/api/v1/protocol?_page=' +
          (curPage + 1) +
          '&_limit=40&_offset=' +
          offset,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        const reverse = data.docs.reverse();
        setItems((items) => [...reverse, ...items]);
        setCurPage((p) => p + 1);
        if (data.hasNextPage === false) setHasMore(false);
      }
    );
  }

  useEffect(() => {
    if (!firstLoad) props.setIsLoading(isLoading);
    else props.setIsLoading(false);
  }, [isLoading]);

  // WHOLE TEXT PROTOCOL
  return (
    <Fragment>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        inverse="true"
        loader={<h4>Mehr laden...</h4>}
        height={'75vh'}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Das waren alle Textprotokolleinträge.</b>
          </p>
        }
      >
        <table className={classes.table}>
          <tbody>
            {items.map((i, index) => (
              <TextProtocolEntry
                key={i._id}
                id={i._id}
                data={i}
                date={i.timestamp}
                keyword={i.keyword}
                message={i.text}
                comment={i.comment ? i.comment : null}
                level={i.level}
              ></TextProtocolEntry>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </Fragment>
  );
}

export default TextProtocol;
