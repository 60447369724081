// import styles
import './App.css';

// import layout component
import Layout from './components/Layout/Layout';
import Login from './components/Views/Login';
import { useContext } from 'react';
import AuthContext from './store/auth-context';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const authCtx = useContext(AuthContext);

  if (!authCtx.isLoggedIn) {
    return <Login />;
  } else {
    return <Layout></Layout>;
  }
}

export default App;
