// import styles
import classes from './MainHeader.module.css';

// import logo
import kauthLogo from '../../assets/kauth_logo_blue.png';

// import header components
import Navigation from './Navigation';
import PushNotificationWrapper from './PushNotificationWrapper';
import useHttp from '../../hooks/use-http';
import AuthContext from '../../store/auth-context';
import { useContext, useEffect, useState } from 'react';

const MainHeader = (props) => {
  const authCtx = useContext(AuthContext);
  const { isLoading, error, sendRequest: getNotifications } = useHttp();
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    getNotifications(
      {
        url: '/api/v1/sensordata/warnings',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        setNotifications(data);
      }
    );
  }, [authCtx.token, getNotifications]);

  // const notifications = [
  //   { message: 'Druckabfall hier', level: 'danger', id: 1 },
  //   { message: 'Druckabfall dort', level: 'warning', id: 2 },
  //   { message: 'Druckabfall und so', level: 'info', id: 3 },
  //   { message: 'so wie das', level: 'success', id: 4 },
  //   { message: 'Das ist zu viel', level: 'danger', id: 5 },
  // ];
  return (
    // display fixed header with three columns
    <div className={classes.wrapper}>
      {/* left column --> LOGO */}
      <div className={classes.left} onClick={props.onLogoClick}>
        <img src={kauthLogo} alt="logo" />
      </div>

      {/* middle column --> PUSH NOTIFICATIONS */}
      <div className={classes.central}>
        <PushNotificationWrapper
          notifications={notifications}
        ></PushNotificationWrapper>
      </div>

      {/* right column --> BUTTONS*/}
      <div className={classes.right}>
        <div className={classes.rightWrapper}>
          <Navigation
            notifications={notifications}
            isDragging={props.isDragging}
          ></Navigation>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
