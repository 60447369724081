import { Fragment, useEffect, useState, useContext } from 'react';
import { DndContext } from '@dnd-kit/core';
import AuthContext from '../../store/auth-context';
import SocketContext from '../../store/socket-context';

// import layout components
import MainHeader from './MainHeader';
import SplitScreen from './SplitScreen';

// import views
import SiloView from '../Views/SiloView';
import TextProtocolView from '../Views/TextProtocolView';
import GraphView from '../Views/GraphView';
import SpeedoView from '../Views/SpeedoView';

function Layout(props) {
  const authCtx = useContext(AuthContext);
  const socketCtx = useContext(SocketContext);
  // used for storing what to display on which side of the screen
  const [leftContent, setLeftContent] = useState(<SiloView></SiloView>);
  const [rightContent, setRightContent] = useState(<GraphView></GraphView>);

  // used for deactivating user-select while dragging
  const [isDragging, setIsDragging] = useState(null);
  // important that dependency array is emtpy
  useEffect(() => {
    if (authCtx.token) socketCtx.initiateSocketConnection(authCtx.token);
  }, []);
  return (
    <Fragment>
      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <MainHeader
          isDragging={isDragging}
          onLogoClick={defaultView}
        ></MainHeader>
        <SplitScreen
          leftContent={leftContent}
          rightContent={rightContent}
        ></SplitScreen>
      </DndContext>
    </Fragment>
  );

  // used for restoring default view by clicking on the logo
  function defaultView() {
    setLeftContent(<SiloView></SiloView>);
    setRightContent(<TextProtocolView></TextProtocolView>);
  }

  // assign the correct content to a draggable (view controller button)
  function getCorrespondingContent(id) {
    switch (id) {
      case 'draggable1':
        return <SiloView></SiloView>;

      case 'draggable2':
        return <SpeedoView></SpeedoView>;

      case 'draggable3':
        return <TextProtocolView></TextProtocolView>;

      case 'draggable4':
        return <GraphView></GraphView>;

      default:
        break;
    }
  }

  // assign the content to a droppable when the draggable was dropped properly
  function handleDragEnd(event) {
    const { over } = event;
    const { active } = event;
    setIsDragging(null);
    if (over === null) return;
    if (
      over.id === 'left' &&
      rightContent.type.name !== getCorrespondingContent(active.id).type.name
    ) {
      setLeftContent(getCorrespondingContent(active.id));
    }
    if (
      over.id === 'right' &&
      leftContent.type.name !== getCorrespondingContent(active.id).type.name
    )
      setRightContent(getCorrespondingContent(active.id));
  }

  function handleDragStart(event) {
    const { active } = event;
    setIsDragging(active.id);
  }
}

export default Layout;
