import { useState, useEffect } from 'react';
import { PushNotificationText } from '../Data/PushNotification';
import Modal from '../UI/Modal';

export default function PushNotificationModal(props) {
  //   const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState({
    header: '',
    body: '',
    headerType: '',
  });
  // notifications array received from MainHeader.js
  const notifications = props.notifications;
  // const reducedNotifications = notifications.slice(0, 3);

  useEffect(() => {
    setModalContent({
      header: 'Auffälligkeiten',
      body: notifications.map((notification) => {
        return (
          <ul key={notification.id}>
            <PushNotificationText
              id={notification.id}
              keyword={notification.keyword}
              sensor={notification.sensor}
              message={notification.text}
              level={notification.level}
            ></PushNotificationText>
          </ul>
        );
      }),
      headerType: 'info',
    });
  }, [notifications]);
  return (
    <Modal modalShow={props.modalShow} setModalShow={props.setModalShow}>
      {modalContent}
    </Modal>
  );
}
