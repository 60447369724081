import React, { Fragment, useState, useEffect, useContext } from 'react';
// import styles
import classes from './Navigation.module.css';

// import layout components
import { Draggable } from '../UI/Draggable';
import { DragOverlay } from '@dnd-kit/core';
import Modal from '../UI/Modal';

// import font awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartColumn,
  faGauge,
  faList,
  faChartLine,
  faCloudArrowDown,
  faQuestion,
  faArrowRightFromBracket,
  faMessage,
} from '@fortawesome/free-solid-svg-icons';
import QuestionAccordion from '../UI/Accordion';

import dateFormat from 'dateformat';
import AuthContext from '../../store/auth-context';
import PushNotificationModal from './PushNotificationModal';

function DownloadReport(props) {
  const [pickedDate, setPickedDate] = useState(
    dateFormat(new Date(), 'yyyy-mm-dd')
  );
  const [dateIsPicked, setDateIsPicked] = useState(false);

  const onSubmit = props.onSubmit;

  function changeDateHandler(event) {
    setPickedDate(event.target.value);
  }

  function submitDateHandler() {
    setDateIsPicked(true);
  }

  useEffect(() => {
    if (dateIsPicked) {
      const success = {
        body: (
          <h4>
            Download des Tagesberichts vom{' '}
            {dateFormat(pickedDate, 'dd.mm.yyyy')} gestartet...
          </h4>
        ),
        headerType: 'success',
      };
      /*const error = {
      //   body: <h4>Etwas ist schiefgelaufen... (siehe Hilfe)</h4>,
      //   headerType: 'danger',
      // }; */
      console.log('Ausgewähltes Datum:', pickedDate);
      onSubmit(success);
      setDateIsPicked(false);
    }
  }, [dateIsPicked, pickedDate, onSubmit]);
  return (
    <Fragment>
      <span>
        Bitte den gewünschten Tag auswählen:
        <input
          className={classes.datePicker}
          type="date"
          value={pickedDate}
          onChange={changeDateHandler}
        />
      </span>
      <input
        type="submit"
        className={classes.downloadButton}
        value="Herunterladen"
        onClick={submitDateHandler}
      />
    </Fragment>
  );
}

function Navigation(props) {
  // modal state variables
  const [feedback, setFeedback] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState({
    header: '',
    body: '',
    headerType: '',
  });
  const authCtx = useContext(AuthContext);
  const [notificationModalShow, setNotificationModalShow] = useState(false);

  // for showing the download modal
  function showDownloadHandler() {
    setModalShow(true);
    setModalContent({
      header: 'Download',
      body: <DownloadReport onSubmit={showDownloadFeedback}></DownloadReport>,
      headerType: 'info',
    });
  }

  // for showing the questions modal
  function showQuestionHandler() {
    setModalShow(true);
    setModalContent({
      header: 'Fragen',
      body: <QuestionAccordion></QuestionAccordion>,
      headerType: 'info',
    });
  }
  useEffect(() => {
    if (feedback !== null) {
      setModalContent({ ...modalContent, ...feedback });
      setFeedback(null);
    }
  }, [modalContent, feedback]);

  function showDownloadFeedback(pFeedback) {
    setFeedback(pFeedback);
  }
  return (
    <Fragment>
      {/* modal with variable content */}
      <Modal modalShow={modalShow} setModalShow={setModalShow}>
        {modalContent}
      </Modal>
      <PushNotificationModal
        modalShow={notificationModalShow}
        setModalShow={setNotificationModalShow}
        notifications={props.notifications}
      />
      {/* VIEW BUTTONS */}
      <div className={classes.wrapper}>
        <span className={classes.viewButtonWrapper}>
          {/* VIEW BUTTONS ON DEFAULT */}
          <Draggable id="draggable1">
            <i>
              <FontAwesomeIcon icon={faChartColumn} />
            </i>
          </Draggable>
          <Draggable id="draggable2">
            <i>
              <FontAwesomeIcon icon={faGauge} />
            </i>
          </Draggable>
          <Draggable id="draggable3">
            <i>
              <FontAwesomeIcon icon={faList} />
            </i>
          </Draggable>
          <Draggable id="draggable4">
            <i>
              <FontAwesomeIcon icon={faChartLine} />
            </i>
          </Draggable>

          {/* VIEW BUTTON OVERLAYS WHILE DRAGGING */}
          <DragOverlay>
            {props.isDragging === 'draggable1' && (
              <i>
                <FontAwesomeIcon icon={faChartColumn} />
              </i>
            )}
            {props.isDragging === 'draggable2' && (
              <i>
                <FontAwesomeIcon icon={faGauge} />
              </i>
            )}
            {props.isDragging === 'draggable3' && (
              <i>
                <FontAwesomeIcon icon={faList} />
              </i>
            )}
            {props.isDragging === 'draggable4' && (
              <i>
                <FontAwesomeIcon icon={faChartLine} />
              </i>
            )}
          </DragOverlay>
        </span>

        {/* MODAL BUTTONS */}
        <span>
          {props.notifications.length > 1 && (
            <i
              onClick={setNotificationModalShow.bind(true)}
              className={`${classes.modalButton} ${classes.notModalButton}`}
              style={{ color: '#E42217' }}
            >
              <FontAwesomeIcon icon={faMessage} />
            </i>
          )}
          <i onClick={showDownloadHandler} className={classes.modalButton}>
            <FontAwesomeIcon icon={faCloudArrowDown} />
          </i>
          <i onClick={showQuestionHandler} className={classes.modalButton}>
            <FontAwesomeIcon icon={faQuestion} />
          </i>
          <i onClick={authCtx.logout} className={classes.modalButton}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </i>
        </span>
      </div>
    </Fragment>
  );
}

export default Navigation;
