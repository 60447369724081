// import styles
import classes from './PushNotification.module.css';

function PushNotification(props) {
  // assign the correct style to a notification level --> danger, warning, info, success
  var notificationLevel = '';
  switch (props.level) {
    case 'danger':
      notificationLevel = classes.danger;
      break;

    case 'warning':
      notificationLevel = classes.warning;
      break;

    case 'info':
      notificationLevel = classes.info;
      break;

    case 'success':
      notificationLevel = classes.success;
      break;

    default:
      notificationLevel = classes.info;
      break;
  }

  return (
    <div className={[classes.notification, notificationLevel].join(' ')}>
      <b>{props.keyword} </b>
      {props.message} {props.sensor && <span>({props.sensor})</span>}
    </div>
  );
}

export default PushNotification;

export function PushNotificationText(props) {
  // assign the correct style to a notification level --> danger, warning, info, success
  var notificationLevel = '';
  switch (props.level) {
    case 'danger':
      notificationLevel = classes.danger;
      break;

    case 'warning':
      notificationLevel = classes.warning;
      break;

    case 'info':
      notificationLevel = classes.info;
      break;

    case 'success':
      notificationLevel = classes.success;
      break;

    default:
      notificationLevel = classes.info;
      break;
  }

  return (
    <li className={[classes.notificationText, notificationLevel].join(' ')}>
      <b>{props.keyword} </b>
      {props.message} {props.sensor && <span>({props.sensor})</span>}
    </li>
  );
}
