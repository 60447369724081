import { useState } from 'react';
// import style
import classes from './SplitScreen.module.css';

// import own split screen components
import { Droppable } from '../UI/Droppable';
import SplitPane, {
  Divider,
  SplitPaneLeft,
  SplitPaneRight,
} from '../UI/SplitPane';

function SplitScreen(props) {
  const [fullscreen, setFullscreen] = useState('none');
  const leftContent = props.leftContent;
  const rightContent = props.rightContent;

  function showFullscreen(childdata) {
    setFullscreen(childdata);
  }

  return (
    <SplitPane className={classes['split-pane-row']}>
      {/* LEFT PANEL */}
      {fullscreen !== 'right' && (
        <SplitPaneLeft onFullscreen={showFullscreen} fullscreen={fullscreen}>
          <Droppable id={'left'}>
            {leftContent !== null && leftContent}
          </Droppable>
        </SplitPaneLeft>
      )}

      {/* DIVIDER */}
      {fullscreen === 'none' && (
        <Divider
          className={`${classes.noSelect} ${classes['separator-col']}`}
        />
      )}

      {/* RIGHT PANEL */}
      {fullscreen !== 'left' && (
        <SplitPaneRight onFullscreen={showFullscreen} fullscreen={fullscreen}>
          <Droppable id={'right'}>
            {rightContent !== null && rightContent}
          </Droppable>
        </SplitPaneRight>
      )}
    </SplitPane>
  );
}

export default SplitScreen;
