import classes from './SiloView.module.css';
import Silo from '../Data/Silo';
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import { useState, useEffect, Fragment } from 'react';
import Modal from '../UI/Modal';
import useHttp from '../../hooks/use-http';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import PageLoader from '../UI/PageLoader';

function SiloView() {
  const scrollRef = useHorizontalScroll();
  const [modalShow, setModalShow] = useState(false);
  const [modalSensor, setModalSensor] = useState(null);
  const [modalContent, setModalContent] = useState({
    header: '',
    body: '',
    headerType: '',
  });
  const authCtx = useContext(AuthContext);
  const [sensors, setSensors] = useState(null);
  const { isLoading, error, sendRequest: getSensors } = useHttp();
  const [errorMessage, setErrorMessage] = useState();
  function closeModal() {
    setModalShow(false);
    setModalSensor(null);
  }
  useEffect(() => {
    getSensors(
      {
        url: '/api/v1/sensordata',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
      },
      (data) => {
        setSensors(data);
      }
    );
  }, [authCtx.token, getSensors]);

  // translate error message
  useEffect(() => {
    if (error === 'User has no linked devices.')
      setErrorMessage('Es wurden keine verknüpften Geräte gefunden.');
  }, [error]);
  return (
    <div className={`${classes.wrapper} alwaysScroll`}>
      <PageLoader loading={isLoading} panel />
      {error && (
        <h1>
          Fehler: <br />
          {errorMessage}
        </h1>
      )}
      {sensors &&
        sensors.groups.map((group) => {
          return (
            <Fragment key={group.name}>
              <h1 className="heading">{group.name}</h1>
              <div className={classes.siloGroup} ref={scrollRef}>
                {group.devices.map((device) => {
                  return (
                    <Silo
                      key={device._id}
                      id={device._id}
                      maxRed={device.maxDanger}
                      maxYellow={device.maxWarn}
                      // maxGreen="160"
                      unit={device.unit}
                      currentValue={device.lastValue}
                      maxValue={device.max}
                      lastUpdated={new Date()}
                      name={device.name}
                      data={device}
                      modalSensor={modalSensor}
                      setModalSensor={setModalSensor}
                      setModalShow={setModalShow}
                      setModalContent={setModalContent}
                    />
                  );
                })}
              </div>
            </Fragment>
          );
        })}
      {/* modal with variable content */}
      <Modal modalShow={modalShow} setModalShow={closeModal} size="md">
        {modalContent}
      </Modal>
    </div>
  );
}

export default SiloView;
