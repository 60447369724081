import classes from './PageLoader.module.css';
import Spinner from 'react-bootstrap/Spinner';

export default function PageLoader(props) {
  if (props.panel) {
    return (
      <div
        className={`${classes.backdropPanel} ${
          props.loading && classes.active
        }`}
      >
        <div className={classes.spinnerPanel}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  } else
    return (
      <div className={`${classes.backdrop} ${props.loading && classes.active}`}>
        <div className={classes.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
}
