import classes from './Silo.module.css';
import dateFormat from 'dateformat';
import { useContext, useEffect, useState } from 'react';
import SocketContext from '../../store/socket-context';
import AuthContext from '../../store/auth-context';

function Silo(props) {
  // assigning props values
  const unit = props.unit;
  const minDanger = props.data.minDanger;
  const maxDanger = props.data.maxDanger;
  const minWarn = props.data.minWarn;
  const maxWarn = props.data.maxWarn;
  const type = props.data.type;
  const lastUpdated = dateFormat(props.lastUpdated, 'd.m.yy, H:MM') + ' Uhr';
  const modalSensor = props.modalSensor;
  const id = props.id;
  const name = props.name;
  const setModalContent = props.setModalContent;
  const [currentValue, setCurrentValue] = useState(props.currentValue);
  const maxRed = parseInt(props.maxRed);
  const maxYellow = parseInt(props.maxYellow);
  const maxGreen = props.maxGreen;

  // initializing contexts
  const authCtx = useContext(AuthContext);
  const socketCtx = useContext(SocketContext);

  // retreiving live data
  useEffect(() => {
    if (authCtx.token && socketCtx.socket) {
      socketCtx.socket.on('sensordata', (data) => {
        if (props.id === data.id) {
          setCurrentValue(data.value);
        }
      });
    }
  }, [authCtx.token, props.id, socketCtx.socket]);

  // function for animation
  const animatePercentage = (setValue, target, reset) => {
    setValue((value) => {
      if (Math.abs(target - value) <= 3) {
        reset();
        return target;
      } else {
        if (target - value > 0) return value + 3;
        if (target - value < 0) return value - 3;
      }
    });
  };

  // // #######################
  // // #### RED THRESHOLD ####
  // // #######################
  const [redPercentage, setRedPercentage] = useState(0);
  let redPercentageTarget = props.maxRed
    ? (props.maxRed / props.maxValue) * 100
    : 0;
  redPercentageTarget =
    Math.round((redPercentageTarget + Number.EPSILON) * 100) / 100;
  const [secondsRed, setSecondsRed] = useState(0);
  const [isActiveTimerRed, setIsActiveTimerRed] = useState(false);
  const [level, setLevel] = useState('info');

  function resetRed() {
    setSecondsRed(0);
    setIsActiveTimerRed(false);
  }

  useEffect(() => {
    let interval = null;
    if (isActiveTimerRed) {
      interval = setInterval(() => {
        setSecondsRed((s) => s + 1);
      }, 1);
    } else if (!isActiveTimerRed && secondsRed !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActiveTimerRed, secondsRed]);

  useEffect(() => {
    setIsActiveTimerRed(true);
  }, [redPercentageTarget]);
  useEffect(() => {
    animatePercentage(setRedPercentage, redPercentageTarget, resetRed);
  }, [secondsRed, redPercentageTarget]);

  // ##########################
  // #### YELLOW THRESHOLD ####
  // ##########################
  const [yellowPercentage, setYellowPercentage] = useState(0);
  let yellowPercentageTarget = props.maxYellow
    ? (props.maxYellow / props.maxValue) * 100
    : 0;
  yellowPercentageTarget =
    Math.round((yellowPercentageTarget + Number.EPSILON) * 100) / 100;
  const [secondsYellow, setSecondsYellow] = useState(0);
  const [isActiveTimerYellow, setIsActiveTimerYellow] = useState(false);

  function resetYellow() {
    setSecondsYellow(0);
    setIsActiveTimerYellow(false);
  }

  useEffect(() => {
    let interval = null;
    if (isActiveTimerYellow) {
      interval = setInterval(() => {
        setSecondsYellow((s) => s + 1);
      }, 1);
    } else if (!isActiveTimerYellow && secondsYellow !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActiveTimerYellow, secondsYellow]);

  useEffect(() => {
    setIsActiveTimerYellow(true);
  }, [yellowPercentageTarget]);
  useEffect(() => {
    animatePercentage(setYellowPercentage, yellowPercentageTarget, resetYellow);
  }, [secondsYellow, yellowPercentageTarget]);

  // #########################
  // #### GREEN THRESHOLD ####
  // #########################
  const [greenPercentage, setGreenPercentage] = useState(0);
  let greenPercentageTarget = props.maxGreen
    ? (props.maxGreen / props.maxValue) * 100
    : 0;
  greenPercentageTarget =
    Math.round((greenPercentageTarget + Number.EPSILON) * 100) / 100;
  const [secondsGreen, setSecondsGreen] = useState(0);
  const [isActiveTimerGreen, setIsActiveTimerGreen] = useState(false);

  function resetGreen() {
    setSecondsGreen(0);
    setIsActiveTimerGreen(false);
  }

  useEffect(() => {
    let interval = null;
    if (isActiveTimerGreen) {
      interval = setInterval(() => {
        setSecondsGreen((s) => s + 1);
      }, 1);
    } else if (!isActiveTimerGreen && secondsGreen !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActiveTimerGreen, secondsGreen]);

  useEffect(() => {
    setIsActiveTimerGreen(true);
  }, [greenPercentageTarget]);
  useEffect(() => {
    animatePercentage(setGreenPercentage, greenPercentageTarget, resetGreen);
  }, [secondsGreen, greenPercentageTarget]);

  // ############################
  // #### CURRENT PERCENTAGE ####
  // ############################
  const [currentPercentage, setCurrentPercentage] = useState(0);
  let currentPercentageTarget = currentValue
    ? (currentValue / props.maxValue) * 100
    : 0;
  currentPercentageTarget =
    Math.round((currentPercentageTarget + Number.EPSILON) * 100) / 100;
  const [secondsCurrent, setSecondsCurrent] = useState(0);
  const [isActiveTimerCurrent, setIsActiveTimerCurrent] = useState(false);

  function resetCurrent() {
    setSecondsCurrent(0);
    setIsActiveTimerCurrent(false);
  }

  useEffect(() => {
    let interval = null;
    if (isActiveTimerCurrent) {
      interval = setInterval(() => {
        setSecondsCurrent((s) => s + 1);
      }, 1);
    } else if (!isActiveTimerCurrent && secondsCurrent !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActiveTimerCurrent, secondsCurrent]);

  useEffect(() => {
    setIsActiveTimerCurrent(true);
  }, [currentPercentageTarget]);
  useEffect(() => {
    animatePercentage(
      setCurrentPercentage,
      currentPercentageTarget,
      resetCurrent
    );
  }, [secondsCurrent, currentPercentageTarget]);

  // updating modal content if something changes (live data)
  useEffect(() => {
    if (modalSensor === id) {
      setModalContent({
        header: name,
        body: (
          <table className={classes.sensorInfo}>
            <tbody>
              <tr>
                <td>Aktueller Wert:</td>
                <td className={classes.infoValue}>
                  {currentValue ? currentValue : '?'} {unit ? unit : '?'}
                </td>
              </tr>
              <tr>
                <td>Warnbereich:</td>
                <td className={classes.infoValue}>
                  {minWarn >= 0 ? minWarn : '?'}
                  {minWarn >= 0 && maxWarn >= 0 && ' - '}
                  {maxWarn >= 0 ? maxWarn : '?'}
                  {unit ? ' ' + unit : '?'}
                </td>
              </tr>
              <tr>
                <td>Gefahrenbereich:</td>
                <td className={classes.infoValue}>
                  {minDanger >= 0 ? minDanger : '?'}
                  {minDanger >= 0 && maxDanger >= 0 && ' - '}
                  {maxDanger >= 0 ? maxDanger : '?'}
                  {unit ? ' ' + unit : '?'}
                </td>
              </tr>
              <tr>
                <td>Sollwert:</td>
                <td className={classes.infoValue}>
                  {maxGreen ? maxGreen : '?'} {unit ? unit : '?'}
                </td>
              </tr>
              <tr>
                <td>Sensorbeschreibung:</td>
                <td className={classes.infoValue}>{type}</td>
              </tr>
              <tr>
                <td>Hinzugefügt:</td>
                <td className={classes.infoValue}>
                  {dateFormat(new Date(), 'd.m.yy, H:MM') + ' Uhr'}
                </td>
              </tr>
              <tr>
                <td>Letzte Aktualisierung:</td>
                <td className={classes.infoValue}>
                  {lastUpdated ? lastUpdated : '?'}
                </td>
              </tr>
              <tr>
                <td>Status:</td>
                <td className={classes.infoValue}>erreichbar</td>
              </tr>
            </tbody>
          </table>
        ),
        headerType: level,
      });
    }
  }, [
    currentValue,
    level,
    maxGreen,
    unit,
    minWarn,
    maxWarn,
    minDanger,
    maxDanger,
    modalSensor,
    id,
    type,
    name,
    lastUpdated,
    setModalContent,
  ]);

  // updating level if something changes (live data)
  useEffect(() => {
    if (currentValue < maxYellow && currentValue > maxRed && maxYellow > maxRed)
      setLevel('warning');
    else if (currentValue < maxRed && props.maxRed) setLevel('danger');
    else if (currentValue > maxRed) setLevel('info');
  }, [currentValue, maxRed, maxYellow, maxGreen, props.maxRed]);

  // showing modal on click
  function showModalHandler() {
    props.setModalShow(true);
    props.setModalSensor(props.id);
    props.setModalContent({
      header: props.name,
      body: (
        <table className={classes.sensorInfo}>
          <tbody>
            <tr>
              <td>Aktueller Wert:</td>
              <td className={classes.infoValue}>
                {currentValue ? currentValue : '?'} {unit ? unit : '?'}
              </td>
            </tr>
            <tr>
              <td>Warnbereich:</td>
              <td className={classes.infoValue}>
                {props.data.minWarn >= 0 ? props.data.minWarn : '?'}
                {props.data.minWarn >= 0 && props.data.maxWarn >= 0 && ' - '}
                {props.data.maxWarn >= 0 ? props.data.maxWarn : '?'}
                {unit ? ' ' + unit : '?'}
              </td>
            </tr>
            <tr>
              <td>Gefahrenbereich:</td>
              <td className={classes.infoValue}>
                {props.data.minDanger >= 0 ? props.data.minDanger : '?'}
                {props.data.minDanger >= 0 &&
                  props.data.maxDanger >= 0 &&
                  ' - '}
                {props.data.maxDanger >= 0 ? props.data.maxDanger : '?'}
                {unit ? ' ' + unit : '?'}
              </td>
            </tr>
            <tr>
              <td>Sollwert:</td>
              <td className={classes.infoValue}>
                {maxGreen ? maxGreen : '?'} {unit ? unit : '?'}
              </td>
            </tr>
            <tr>
              <td>Sensorbeschreibung:</td>
              <td className={classes.infoValue}>{props.data.type}</td>
            </tr>
            <tr>
              <td>Hinzugefügt:</td>
              <td className={classes.infoValue}>
                {dateFormat(new Date(), 'd.m.yy, H:MM') + ' Uhr'}
              </td>
            </tr>
            <tr>
              <td>Letzte Aktualisierung:</td>
              <td className={classes.infoValue}>
                {props.lastUpdated
                  ? dateFormat(props.lastUpdated, 'd.m.yy, H:MM') + ' Uhr'
                  : '?'}
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td className={classes.infoValue}>erreichbar</td>
            </tr>
          </tbody>
        </table>
      ),
      headerType: level,
    });
  }

  return (
    <div
      className={classes.container}
      data-mode={level}
      onClick={showModalHandler}
    >
      <div className={classes.name}>{props.name}</div>
      {/* outer gray border */}
      <div className={classes.bar}>
        {/* inner white border */}
        {/* RED NUMBER */}
        {(redPercentageTarget < greenPercentageTarget || !props.maxGreen) &&
          (redPercentageTarget < greenPercentageTarget || !props.maxGreen) && (
            <span
              style={{
                top: `calc(${100 - redPercentage}% - 10px)`,
                animation: 'all ease-in-out 0.4s',
              }}
            >
              {maxRed + ' ' + unit}
            </span>
          )}
        {/* YELLOW NUMBER */}
        {(redPercentageTarget < yellowPercentageTarget || !props.maxRed) &&
          (yellowPercentageTarget < greenPercentageTarget ||
            !props.maxGreen) && (
            <span
              style={{
                top: `calc(${100 - yellowPercentage}% - 10px)`,
                animation: 'all ease-in-out 0.4s',
              }}
            >
              {maxYellow + ' ' + unit}
            </span>
          )}
        {/* GREEN NUMBER */}
        {props.maxGreen && (
          <span
            style={{
              top: `calc(${100 - greenPercentage}% - 5px - ${
                (50 - redPercentage) / 20
              }px)`,
              animation: 'top ease-in-out 0.4s',
            }}
          >
            {maxGreen ? maxGreen : '?'} {unit ? unit : '?'}
          </span>
        )}
        <div className={classes.mask}>
          {/* gray background */}
          <div className={classes.barDisplay}></div>
          {/* RED BAR */}
          {(redPercentageTarget < greenPercentageTarget || !props.maxGreen) &&
            (redPercentageTarget < greenPercentageTarget ||
              !props.maxGreen) && (
              <div
                className={classes.redRange}
                style={{ top: `calc(${100 - redPercentage}%)` }}
              />
            )}
          {/* YELLOW BAR */}
          {(redPercentageTarget < yellowPercentageTarget || !props.maxRed) &&
            (yellowPercentageTarget < greenPercentageTarget ||
              !props.maxGreen) && (
              <div
                className={classes.yellowRange}
                style={{ top: `calc(${100 - yellowPercentage}%)` }}
              ></div>
            )}
          {/* GREEN LINE */}
          {props.maxGreen && (
            <div
              className={classes.greenLine}
              style={{ top: `calc(${100 - greenPercentage}%)` }}
            />
          )}
        </div>
        {/* CURRENT LINE */}
        <div
          className={classes.pointer}
          style={{ top: `calc(${100 - currentPercentage}%)` }}
        ></div>
      </div>
      {/* CARD BELOW */}
      <div className={classes.card}>
        <span className={classes.title}>
          {currentValue ? currentValue : '?'} {unit ? unit : '?'}
        </span>
        <br />
        <span className={classes.info}>
          {props.lastUpdated ? lastUpdated : '?'}
        </span>
      </div>
    </div>
  );
}

export default Silo;
