// import styles
import classes from './Modal.module.css';

// import modal from library
import ReactModal from 'react-bootstrap/Modal';

function ModalOverlay(props) {
  // color of header --> danger, warning, info, success
  let headerType = '';
  // color of closing x --> white or null (--> black)
  let closeVariant = null;

  // assign styles to corresponding header types
  switch (props.headertype) {
    case 'danger':
      headerType = classes.danger;
      closeVariant = 'white';
      break;

    case 'warning':
      headerType = classes.warning;
      closeVariant = 'white';
      break;

    case 'info':
      headerType = classes.info;
      closeVariant = null;
      break;

    case 'success':
      headerType = classes.success;
      closeVariant = null;
      break;

    default:
      headerType = classes.info;
      closeVariant = null;
      break;
  }
  return (
    <ReactModal
      {...props}
      size={props.size || 'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName={classes.backdrop}
      dialogClassName={classes.dialog}
      contentClassName={classes.content}
      style={props.show && { opacity: 1 }}
    >
      <ReactModal.Header
        closeButton
        closeVariant={closeVariant}
        className={`${headerType} noSelect`}
      >
        <ReactModal.Title id="contained-modal-title-vcenter">
          {props.header}
        </ReactModal.Title>
      </ReactModal.Header>
      <ReactModal.Body className={classes.body}>{props.body}</ReactModal.Body>
    </ReactModal>
  );
}

function Modal(props) {
  // whether modal is active
  const modalShow = props.modalShow;

  // modal content var and spliced vars
  const modalContent = props.children;
  const modalHeader = modalContent.header;
  const modalBody = modalContent.body;
  const modalHeaderType = modalContent.headerType;
  return (
    <ModalOverlay
      show={modalShow}
      header={modalHeader}
      body={modalBody}
      headertype={modalHeaderType}
      onHide={() => props.setModalShow(false)}
      size={props.size}
    />
  );
}

export default Modal;
