import { Fragment } from 'react';
import classes from './GraphView.module.css';
// import graph component
import Graph from '../Data/Graph';

function GraphView() {
  // data array for the draph line
  const graphValues = [
    {
      name: '1 Uhr',
      '2-4': 4000,
      '1-1': 2400,
      amt: 2400,
    },
    {
      name: '2 Uhr',
      '2-4': 3000,
      '1-1': 1398,
      amt: 2210,
    },
    {
      name: '3 Uhr',
      '2-4': 2000,
      '1-1': 9800,
      amt: 2290,
    },
    {
      name: '4 Uhr',
      '2-4': 2780,
      '1-1': 3908,
      amt: 2000,
    },
    {
      name: '5 Uhr',
      '2-4': 1890,
      '1-1': 4800,
      amt: 2181,
    },
    {
      name: '6 Uhr',
      '2-4': 2390,
      '1-1': 3800,
      amt: 2500,
    },
    {
      name: '7 Uhr',
      '2-4': 3490,
      '1-1': 4300,
      amt: 2100,
    },
  ];

  // data array for which sensor to display as selection
  const sensors = [
    [
      {
        id: 1,
        name: '1-1',
      },
      {
        id: 2,
        name: '1-2',
      },
      {
        id: 3,
        name: '1-3',
      },
      {
        id: 4,
        name: '1-4',
      },
      {
        id: 5,
        name: '1-5',
      },
      {
        id: 6,
        name: '1-6',
      },
    ],
    [
      {
        id: 7,
        name: '2-1',
      },
      {
        id: 8,
        name: '2-2',
      },
      {
        id: 9,
        name: '2-3',
      },
      {
        id: 10,
        name: '2-4',
      },
      {
        id: 11,
        name: '2-5',
      },
      {
        id: 12,
        name: '2-6',
      },
    ],
  ];

  return (
    <Fragment>
      <h1 className={`${classes.viewHeader} heading`}>Graph</h1>
      <div className={classes.wrapper}>
        <Graph graphValues={graphValues} sensors={sensors}></Graph>
      </div>
    </Fragment>
  );
}

export default GraphView;
