import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import scryptic_logo from '../../assets/scryptic_logo.png';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function QuestionAccordion() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const infos = [
    {
      id: 1,
      header: (
        <span>
          Wie kann ich die <b>Bildschirmansicht</b> verhändern?
        </span>
      ),
      body: (
        <span>
          Per <b>drag and drop</b> kann man die Symbole auf die gewünschte
          Bildschirmhälfte ziehen. <br /> Die Größen der Bildschirmhälften kann
          man durch Herüberschieben des Mittelbalkens verändern. <br /> Durch
          die Pfeilbuttons oben rechts kann man den Vollbildmodus einstellen
          oder verlassen.
        </span>
      ),
    },
    {
      id: 2,
      header: (
        <span>
          Wie kann man einen <b>Tagesbericht</b> herunterladen?
        </span>
      ),
      body: (
        <span>
          Dazu auf die <b>Wolke mit dem Pfeil nach unten</b> klicken, den
          jeweiligen Tag auswählen und auf "Herunterladen" klicken.
        </span>
      ),
    },
    {
      id: 3,
      header: (
        <span>
          Wie komme ich auf die <b>Standardeinstellung der Ansichten</b> zurück?
        </span>
      ),
      body: (
        <span>
          Dazu einfach auf das <b>Logo</b> klicken.
        </span>
      ),
    },
    {
      id: 4,
      header: (
        <span>
          Wie kann ich einen <b>Kommentar</b> zur einem Textprotokoll eintrag
          setzen?
        </span>
      ),
      body: (
        <span>
          Dazu auf den <b>Stift</b> klicken, den Text eingeben bzw. bearbeiten
          und bestätigen. <br /> Alternativ kann die Eingabe über das X-Symbol
          abgebrochen werden.
        </span>
      ),
    },
    {
      id: 5,
      header: (
        <span>
          Wie kann ich die Ansicht des <b>Graphen</b> anpassen?
        </span>
      ),
      body: (
        <span>
          Dazu oben die gewünschten Sensoren und unten die zu betrachtende
          Zeitspanne anwählen.
        </span>
      ),
    },
    {
      id: 6,
      header: <span>Wer hat die Anwendung gemacht?</span>,
      body: (
        <span>
          <b>Hi, wir sind scryptic;</b>
          <img
            src={scryptic_logo}
            alt=""
            width="200px"
            style={{ float: 'right' }}
          ></img>{' '}
          <br /> Wir haben diese Applikation entwickelt, um das Monitoring
          übersichtlicher und intuitiver zu machen. <br /> Bei Lob, Kritik und
          Fehlermeldung können Sie uns gerne über{' '}
          <a href="mailto:hi@scryptic.io">hi@scryptic.io</a> Bescheid geben.
        </span>
      ),
    },
  ];

  return (
    <div>
      {infos.map((info) => {
        return (
          <Accordion
            key={info.id}
            expanded={expanded === `panel${info.id}`}
            onChange={handleChange(`panel${info.id}`)}
          >
            <AccordionSummary
              aria-controls={`panel${info.id}d-content`}
              id={`panel${info.id}d-header`}
            >
              <Typography>{info.header}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{info.body}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
