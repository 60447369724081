import classes from './Login.module.css';
import { useState, useContext } from 'react';
import useHttp from '../../hooks/use-http';
import AuthContext from '../../store/auth-context';
import SocketContext from '../../store/socket-context';
import PageLoader from '../UI/PageLoader';

function Login(props) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const { isLoading, error, sendRequest: login } = useHttp();
  const authCtx = useContext(AuthContext);
  const socketCtx = useContext(SocketContext);

  function usernameHandler(event) {
    setUsername(event.target.value);
  }
  function passwordHandler(event) {
    setPassword(event.target.value);
  }

  async function loginHandler(event) {
    event.preventDefault();
    login(
      {
        url: '/api/v1/auth/login',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          username: username,
          password: password,
        },
      },
      (data) => {
        authCtx.login(data.token);
        socketCtx.initiateSocketConnection(data.token);
      }
    );
  }

  function translateError() {
    let errorMessage = null;
    if (error === 'Not authenticated.') errorMessage = 'Falsches Passwort.';
    if (error === 'user not found.')
      errorMessage = 'Benutzername nicht gefunden.';
    return errorMessage;
  }

  return (
    <div className={classes.backdrop}>
      <PageLoader loading={isLoading} />
      <div className={classes.loginWrapper}>
        <form className={classes.loginForm} onSubmit={loginHandler}>
          {error && <p className={classes.error}>{translateError(error)}</p>}
          <label>
            <p>Benutzername</p>
            <input type="text" onChange={usernameHandler} />
          </label>
          <label>
            <p>Passwort</p>
            <input type="password" onChange={passwordHandler} />
          </label>
          <div>
            <button type="submit" className={classes.formSubmit}>
              Bestätigen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
