import { Fragment, useState } from 'react';

// import styles
import classes from './TextProtocolView.module.css';

// import text protocol data component
import TextProtocol from '../Data/TextProtocol';

import PageLoader from '../UI/PageLoader';

function TextProtocolView() {
  const [isLoading, setIsLoading] = useState();

  return (
    <Fragment>
      <PageLoader loading={isLoading} panel />
      <h1 className={`${classes.viewHeader} heading`}>Textprotokoll</h1>
      {/* the two seperate divs are style relevant */}
      <div className={classes.scrollable}>
        <div className={classes.wrapper}>
          <TextProtocol setIsLoading={setIsLoading} />
        </div>
      </div>
    </Fragment>
  );
}

export default TextProtocolView;
