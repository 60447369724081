import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    width: '100%',
    float: 'left',
    marginTop: '-10px',
    paddingTop: '10px',
    height: '100%',
  };

  const borderStyle = {
    height: '100%',
    padding: '10px',
    border: '3px solid transparent',
    borderColor: isOver ? 'lightblue' : 'transparent',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div style={borderStyle}>{props.children}</div>
    </div>
  );
}
