import { Fragment, useState, useEffect } from 'react';
// import styles
import classes from './Graph.module.css';
// import graph library
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function Graph(props) {
  const [selectedSensors, setSelectedSensor] = useState([]);
  const [selectedTime, setSelectedTime] = useState('hour');

  const timeSelectionOptions = [
    { id: 1, name: 'hour', label: 'Stunde' },
    { id: 2, name: 'day', label: 'Tag' },
    { id: 3, name: 'week', label: 'Woche' },
    { id: 4, name: 'month', label: 'Monat' },
  ];

  // receive data arrays from the graph view
  const graphValues = props.graphValues;
  const sensors = props.sensors;

  function onSensorSelectionChangeHandler(event) {
    if (selectedSensors.indexOf(event.target.value) < 0) {
      setSelectedSensor([...selectedSensors, event.target.value]);
    } else {
      setSelectedSensor(
        selectedSensors.filter((sensor) => {
          return sensor !== event.target.value;
        })
      );
    }
  }

  function timeSelectionHandler(event) {
    setSelectedTime(event.target.value);
  }

  useEffect(() => {
    console.log('Ausgewählte Zeit:', selectedTime);
  }, [selectedTime]);

  useEffect(() => {
    console.log('Ausgewählte Sensoren:', selectedSensors);
  }, [selectedSensors]);

  return (
    <Fragment>
      {/* SELECTOR BUTTONS   */}
      {/* loop through the sensor groups (e. g. different halls) */}
      {sensors.map((group) => {
        return (
          <div key={sensors.indexOf(group)} className={classes.sensorSelector}>
            {/* loop through the different sensors in a group */}
            {group.map((sensor) => {
              const defaultCheckedValue =
                selectedSensors.indexOf(sensor.name) > 0 ? true : false;
              return (
                // buttons for displaying values of a sensor in the graph
                <Fragment key={sensor.id}>
                  <input
                    type="checkbox"
                    name="sensorCheckbox"
                    id={`sensorCheckbox-${sensor.id}`}
                    onChange={onSensorSelectionChangeHandler}
                    value={sensor.name}
                    defaultChecked={defaultCheckedValue}
                  />
                  <label htmlFor={`sensorCheckbox-${sensor.id}`}>
                    {sensor.name}
                  </label>
                </Fragment>
              );
            })}
          </div>
        );
      })}

      {/* GRAPH */}
      <ResponsiveContainer width="100%" height="60%">
        <LineChart
          width={500}
          height={300}
          data={graphValues}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="1-1"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="2-4" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>

      {/* TIME SELECTOR BUTTONS */}
      <fieldset
        className={classes.timeSelector}
        onChange={timeSelectionHandler}
      >
        {timeSelectionOptions.map((option) => {
          const defaultCheckedValue =
            option.name === selectedTime ? true : false;
          return (
            <Fragment key={option.id}>
              <input
                type="radio"
                name="timeSpan"
                value={option.name}
                id={option.name}
                defaultChecked={defaultCheckedValue}
              />
              <label htmlFor={option.name}>{option.label}</label>
            </Fragment>
          );
        })}
      </fieldset>
    </Fragment>
  );
}

export default Graph;
