import { useState } from 'react';

// import styles
import classes from './PushNotificationWrapper.module.css';

// import single push notification
import PushNotification from '../Data/PushNotification';

import { Fragment } from 'react';
import PushNotificationModal from './PushNotificationModal';

function PushNotificationWrapper(props) {
  const [modalShow, setModalShow] = useState(false);
  // notifications array received from MainHeader.js
  const notifications = props.notifications;
  // const reducedNotifications = notifications.slice(0, 3);

  return (
    <Fragment>
      <PushNotificationModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        notifications={notifications}
      />
      <div className={classes.notificationWrapper}>
        {/* loop through notifications */}
        {notifications.map((notification) => {
          if (
            notifications.length <= 4 ||
            notifications.indexOf(notification) < 3
          ) {
            return (
              // span = style relevant
              <span
                key={notification.id}
                className={`${classes.animated} ${classes.animatedFadeInUp} ${classes.fadeInUp} ${classes.limitSize}`}
              >
                <PushNotification
                  id={notification.id}
                  keyword={notification.keyword}
                  sensor={notification.sensor}
                  message={notification.text}
                  level={notification.level}
                ></PushNotification>
              </span>
            );
          } else return null;
        })}
        {notifications.length > 4 && (
          <span
            className={`${classes.animated} ${classes.animatedFadeInUp} ${classes.fadeInUp} ${classes.limitSize} ${classes.moreNotes}`}
            onClick={setModalShow.bind(true)}
          >
            <PushNotification
              message={'mehr...'}
              level={'info'}
              sensor={null}
              keyword={null}
            ></PushNotification>
          </span>
        )}
      </div>
    </Fragment>
  );
}

export default PushNotificationWrapper;
