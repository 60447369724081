import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// import styles
import classes from './SplitPane.module.css';

// import modal contect provider
import SplitPaneContext from '../../store/SplitPaneContext';

// import fontawesome icons for fullscreen and non-fullscreen
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';

const SplitPane = ({ children, ...props }) => {
  const [clientWidth, setClientWidth] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const xDividerPos = useRef(null);

  const onMouseHoldDown = (e) => {
    xDividerPos.current = e.clientX;
    setIsDragging(true);
  };

  const onMouseHoldUp = () => {
    xDividerPos.current = null;
    setIsDragging(false);
  };

  const onMouseHoldMove = (e) => {
    if (!xDividerPos.current) {
      return;
    }
    setClientWidth(clientWidth + e.clientX - xDividerPos.current);

    xDividerPos.current = e.clientX;
  };

  useEffect(() => {
    document.addEventListener('mouseup', onMouseHoldUp);
    document.addEventListener('mousemove', onMouseHoldMove);

    return () => {
      document.removeEventListener('mouseup', onMouseHoldUp);
      document.removeEventListener('mousemove', onMouseHoldMove);
    };
  });

  return (
    <div className={`${isDragging && classes.noSelect}`}>
      <div {...props}>
        <SplitPaneContext.Provider
          value={{
            clientWidth,
            setClientWidth,
            onMouseHoldDown,
          }}
        >
          {children}
        </SplitPaneContext.Provider>
      </div>
    </div>
  );
};

export const Divider = (props) => {
  const { onMouseHoldDown } = useContext(SplitPaneContext);

  return <div {...props} onMouseDown={onMouseHoldDown} />;
};

export const SplitPaneLeft = (props) => {
  const topRef = createRef();
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);

  function fullscreenHandler() {
    topRef.current.style.minWidth = '100%';
    topRef.current.style.maxWidth = '100%';
    props.onFullscreen('left');
  }
  function splitscreenHandler() {
    topRef.current.style.minWidth = '50%';
    topRef.current.style.maxWidth = '50%';
    props.onFullscreen('none');
  }
  useEffect(() => {
    if (topRef.current.style.minWidth !== '100%') {
      if (!clientWidth) {
        setClientWidth(topRef.current.clientWidth);
        return;
      }

      topRef.current.style.minWidth = clientWidth + 'px';
      topRef.current.style.maxWidth = clientWidth + 'px';
    }
  }, [clientWidth, setClientWidth, topRef]);

  return (
    <div className={classes['split-pane-left']} ref={topRef}>
      <div
        className={`${classes.fullsizeButtonWrapper} fullSizeButtonPosition`}
      >
        {props.fullscreen !== 'left' && (
          <i
            className={classes.fullsizeButton}
            onClick={() => fullscreenHandler()}
          >
            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
          </i>
        )}
        {props.fullscreen === 'left' && (
          <i
            className={classes.fullsizeButton}
            onClick={() => splitscreenHandler()}
          >
            <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
          </i>
        )}
      </div>
      {props.children}
    </div>
  );
};

export const SplitPaneRight = (props) => {
  return (
    <div className={classes['split-pane-right']}>
      <div
        className={`${classes.fullsizeButtonWrapper} fullSizeButtonPosition`}
      >
        {props.fullscreen !== 'right' && (
          <i
            className={classes.fullsizeButton}
            onClick={() => props.onFullscreen('right')}
          >
            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
          </i>
        )}
        {props.fullscreen === 'right' && (
          <i
            className={classes.fullsizeButton}
            onClick={() => props.onFullscreen('none')}
          >
            <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
          </i>
        )}
      </div>
      {props.children}
    </div>
  );
};

export default SplitPane;
