import classes from './Speedo.module.css';
import dateFormat from 'dateformat';
import { useContext, useEffect, useState } from 'react';
import SocketContext from '../../store/socket-context';
import AuthContext from '../../store/auth-context';

const mapNum = (value, x1, y1, x2, y2) =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;

function getScale(maxValue) {
  let scale = [null];
  for (let i = 0; i < 11; i++) {
    scale[i] = (maxValue / 10) * i;
    scale[i] = Math.round(scale[i]);
  }
  return scale;
}

function getKnobRotation(currentValue, maxValue) {
  const percentage = currentValue / maxValue;
  return mapNum(percentage, 0, 1, -140, 140);
}

function getRangePercent(value, maxValue, arc) {
  const percentage = (value / maxValue) * 100;
  const percentNormalized = Math.min(Math.max(percentage, 0), 100);
  return arc - (percentNormalized / 100) * arc;
}

const animatePercentage = (setValue, target, reset) => {
  setValue((value) => {
    if (Math.abs(target - value) <= 3) {
      reset();
      return target;
    } else {
      if (target - value > 0) return value + 3;
      if (target - value < 0) return value - 3;
    }
  });
};

function Speedo(props) {
  // Style constans
  const radius = 108.164;
  const arc = radius * Math.PI * (280 / 360);
  const dashArray = `${arc} ${radius * Math.PI}`;
  const [knobRotation, setKnobRotation] = useState(0);
  const [knobRotationTarget, setKnobRotationTarget] = useState(0);
  const [level, setLevel] = useState('info');

  // Assigning prop values
  const unit = props.unit;
  const [currentValue, setCurrentValue] = useState(props.currentValue);
  const maxValue = props.maxValue;
  const lastUpdated = props.lastUpdated;
  const scale = getScale(props.maxValue);
  const minDanger = props.data.minDanger;
  const maxDanger = props.data.maxDanger;
  const minWarn = props.data.minWarn;
  const maxWarn = props.data.maxWarn;
  const type = props.data.type;
  const maxGreen = props.maxGreen;
  const modalSensor = props.modalSensor;
  const id = props.id;
  const name = props.name;
  const setModalContent = props.setModalContent;
  const lastUpdatedFormat =
    dateFormat(props.lastUpdated, 'd.m.yy, H:M') + ' Uhr';

  // initializing contexts
  const authCtx = useContext(AuthContext);
  const socketCtx = useContext(SocketContext);

  // retreiving live data
  useEffect(() => {
    if (authCtx.token && socketCtx.socket) {
      socketCtx.socket.on('sensordata', (data) => {
        if (props.id === data.id) {
          setCurrentValue(data.value);
        }
      });
    }
  }, [authCtx.token, props.id, socketCtx.socket]);

  // calculate rotation of knob through current and max value
  useEffect(() => {
    setKnobRotationTarget(
      currentValue && props.maxValue
        ? getKnobRotation(currentValue, props.maxValue)
        : 0
    );
  }, [currentValue, props.maxValue]);

  // updating modal if something changes (live data)
  useEffect(() => {
    if (modalSensor === id) {
      setModalContent({
        header: name,
        body: (
          <table className={classes.sensorInfo}>
            <tbody>
              <tr>
                <td>Aktueller Wert:</td>
                <td className={classes.infoValue}>
                  {currentValue} {unit}
                </td>
              </tr>
              <tr>
                <td>Sollwert:</td>
                <td className={classes.infoValue}>180 {unit}</td>
              </tr>
              <tr>
                <td>Warnbereich:</td>
                <td className={classes.infoValue}>
                  {minWarn >= 0 ? minWarn : '?'}
                  {minWarn >= 0 && maxWarn >= 0 && ' - '}
                  {maxWarn >= 0 ? maxWarn : '?'}
                  {unit ? ' ' + unit : '?'}
                </td>
              </tr>
              <tr>
                <td>Gefahrenbereich:</td>
                <td className={classes.infoValue}>
                  {minDanger >= 0 ? minDanger : '?'}
                  {minDanger >= 0 && maxDanger >= 0 && ' - '}
                  {maxDanger >= 0 ? maxDanger : '?'}
                  {unit ? ' ' + unit : '?'}
                </td>
              </tr>
              {maxGreen && (
                <tr>
                  <td>Sollwert:</td>
                  <td className={classes.infoValue}>
                    {maxGreen ? maxGreen : '?'} {unit ? unit : '?'}
                  </td>
                </tr>
              )}
              <tr>
                <td>Sensorbeschreibung:</td>
                <td className={classes.infoValue}>{type}</td>
              </tr>
              <tr>
                <td>Hinzugefügt:</td>
                <td className={classes.infoValue}>
                  {dateFormat(new Date(), 'd.m.yy, H:M') + ' Uhr'}
                </td>
              </tr>
              <tr>
                <td>Letzte Aktualisierung:</td>
                <td className={classes.infoValue}>{lastUpdatedFormat}</td>
              </tr>
              <tr>
                <td>Status:</td>
                <td className={classes.infoValue}>erreichbar</td>
              </tr>
            </tbody>
          </table>
        ),
        headerType: level,
      });
    }
  }, [
    modalSensor,
    currentValue,
    level,
    unit,
    minWarn,
    maxWarn,
    minDanger,
    maxDanger,
    maxGreen,
    id,
    name,
    setModalContent,
    lastUpdatedFormat,
    type,
  ]);

  // #################################
  // #### KNOB ROTATION ANIMATION ####
  // #################################
  const [seconds, setSeconds] = useState(0);
  const [isActiveTimer, setIsActiveTimer] = useState(true);

  function reset() {
    setSeconds(0);
    setIsActiveTimer(false);
  }

  useEffect(() => {
    let interval = null;
    if (isActiveTimer) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1);
    } else if (!isActiveTimer && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActiveTimer, seconds]);

  useEffect(() => {
    setIsActiveTimer(true);
  }, [knobRotationTarget]);

  useEffect(() => {
    animatePercentage(setKnobRotation, knobRotationTarget, reset);
  }, [seconds, knobRotationTarget]);

  const offsetRed = getRangePercent(
    parseInt(props.redMax),
    props.maxValue,
    arc
  );
  const offsetYellow = getRangePercent(
    parseInt(props.yellowMax),
    maxValue,
    arc
  );

  // update level if something changes (live data)
  useEffect(() => {
    if (
      currentValue < parseInt(props.yellowMax) &&
      currentValue > parseInt(props.redMax)
    )
      setLevel('warning');
    else if (currentValue < parseInt(props.redMax)) setLevel('danger');
    else if (currentValue > parseInt(props.redMax)) setLevel('info');
  }, [currentValue, props.yellowMax, props.redMax]);

  // show modal on click
  function showModalHandler() {
    props.setModalShow(true);
    props.setModalSensor(props.id);
    props.setModalContent({
      header: props.name,
      body: (
        <table className={classes.sensorInfo}>
          <tbody>
            <tr>
              <td>Aktueller Wert:</td>
              <td className={classes.infoValue}>
                {currentValue} {unit}
              </td>
            </tr>
            <tr>
              <td>Sollwert:</td>
              <td className={classes.infoValue}>180 {unit}</td>
            </tr>
            <tr>
              <td>Warnbereich:</td>
              <td className={classes.infoValue}>
                {props.data.minWarn >= 0 ? props.data.minWarn : '?'}
                {props.data.minWarn >= 0 && props.data.maxWarn >= 0 && ' - '}
                {props.data.maxWarn >= 0 ? props.data.maxWarn : '?'}
                {unit ? ' ' + unit : '?'}
              </td>
            </tr>
            <tr>
              <td>Gefahrenbereich:</td>
              <td className={classes.infoValue}>
                {props.data.minDanger >= 0 ? props.data.minDanger : '?'}
                {props.data.minDanger >= 0 &&
                  props.data.maxDanger >= 0 &&
                  ' - '}
                {props.data.maxDanger >= 0 ? props.data.maxDanger : '?'}
                {unit ? ' ' + unit : '?'}
              </td>
            </tr>
            {props.data.maxGreen && (
              <tr>
                <td>Sollwert:</td>
                <td className={classes.infoValue}>
                  {props.maxGreen ? props.maxGreen : '?'} {unit ? unit : '?'}
                </td>
              </tr>
            )}
            <tr>
              <td>Sensorbeschreibung:</td>
              <td className={classes.infoValue}>{props.data.type}</td>
            </tr>
            <tr>
              <td>Hinzugefügt:</td>
              <td className={classes.infoValue}>
                {dateFormat(new Date(), 'd.m.yy, H:M') + ' Uhr'}
              </td>
            </tr>
            <tr>
              <td>Letzte Aktualisierung:</td>
              <td className={classes.infoValue}>
                {dateFormat(props.lastUpdated, 'd.m.yy, H:M') + ' Uhr'}
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td className={classes.infoValue}>erreichbar</td>
            </tr>
          </tbody>
        </table>
      ),
      headerType: level,
    });
  }

  return (
    <div className={classes.container}>
      <span className={classes.name}>{props.name}</span>
      <svg
        viewBox="0,0,216.328,243"
        className={classes.speedo}
        onClick={showModalHandler}
      >
        <g className="radial-gauge">
          <circle
            cx="108.164"
            cy="121.5"
            r="108.164"
            style={{ stroke: 'rgb(216, 216, 216)', fill: 'rgb(245, 245, 245)' }}
          />
          <circle
            className={classes.gaugePercent}
            cx={radius}
            cy="121.5"
            r={radius / 2}
            fill="transparent"
            stroke="rgb(245, 166, 35)"
            strokeDasharray={dashArray}
            strokeWidth={radius}
            strokeDashoffset={offsetYellow}
          />
          <circle
            className={classes.gaugePercent}
            cx="108.164"
            cy="121.5"
            fill="transparent"
            r={radius / 2}
            stroke="rgb(208, 2, 27)"
            strokeDasharray={dashArray}
            strokeWidth={radius}
            strokeDashoffset={offsetRed}
          />
        </g>
        <g className="radial-gauge-scale">
          {/* inner circle --> not visible */}
          <circle
            cx="108.164"
            cy="121.5"
            r="75.7148"
            style={{ stroke: 'rgb(241, 241, 241)', fill: 'rgb(255, 255, 255)' }}
          />
          {/* START OF LINES TO MARK NUMBERS */}
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(-140,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(-112,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(-84,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(-56,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(-28,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(0,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(28,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(55.99999999999999,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(84,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(112,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          <line
            x1="108.164"
            y1="45.7852"
            x2="108.164"
            y2="40.376999999999995"
            transform="rotate(140,108.164,121.5)"
            style={{ stroke: 'rgb(255, 255, 255)', strokeWidth: 5 }}
          />
          {/* END OF LINES TO MARK NUMBERS */}
          {/* START OF NUMBERS --> min, 9 in between, max --> 11 in total */}
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(-140,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[0]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(-112,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[1]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(-84,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[2]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(-56,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[3]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(-28,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[4]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(0,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[5]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(28,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[6]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(55.99999999999999,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[7]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(84,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[8]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(112,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {scale[9]}
          </text>
          <text
            x="108.164"
            y="34.96879999999999"
            textAnchor="middle"
            fill="#444444"
            transform="rotate(140,108.164,121.5)"
            style={{ fontSize: '0.77em', fontWeight: 'bold' }}
          >
            {/* max value */} {scale[10]}
          </text>
          {/* END OF NUMBERS --> min, 9 in between, max --> 11 in total */}
        </g>
        <g
          className={classes.radialGaugePointerKnob}
          transform={`rotate(${knobRotation},108.164,121.5)`}
        >
          {/* circle part of knob */}
          <circle
            cx="108.164"
            cy="121.5"
            r="59.49020000000001"
            style={{ stroke: 'rgb(51, 51, 51)', fill: 'rgb(51, 51, 51)' }}
          />
          {/* triangle to mark apex of knob */}
          <path
            d="M48.67379999999999,121.5L167.6542,121.5L108.164,34.96879999999999"
            fill="#333333"
          />
        </g>
        <text
          x="108.164"
          y="99.8672"
          fill="#FFFFFF"
          textAnchor="middle"
          style={{ fontSize: '1.06em' }}
        >
          {currentValue ? currentValue : '?'}
        </text>
        <text
          id="text-unit"
          x="108.164"
          y="121.5"
          fill="#F8F8F8"
          textAnchor="middle"
          style={{ fontSize: '0.77em' }}
        >
          {unit ? unit : '?'}
        </text>
        <text
          id="text-time"
          x="108.164"
          y="143.1328"
          fill="#F8F8F8"
          textAnchor="middle"
          fontStyle="italic"
          style={{ fontSize: '0.77em', maxWidth: '20%' }}
        >
          {lastUpdated ? dateFormat(lastUpdated, 'd.m.yy, H:M') + ' Uhr' : '?'}
        </text>
      </svg>
    </div>
  );
}

export default Speedo;
