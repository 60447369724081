import { createContext, useState, useEffect } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext({
  socket: '',
  initiateSocketConnection: () => {},
});

export const SocketContextProvider = (props) => {
  const [socket, setSocket] = useState(null);
  function initiateSocketConnectionHandler(token) {
    setSocket(
      io('https://app.wpc-online.de', {
        auth: {
          token: token,
        },
      })
    );
  }
  const contextValue = {
    socket: socket,
    initiateSocketConnection: initiateSocketConnectionHandler,
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketContext;
